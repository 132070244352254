class ActivityReportsOverview {
  $location:             any;
  $scope:                any;
  $rootScope:            any;
  appFactory:            any;
  databaseService:       any;
  errFactory:            any;
  loaderFactory:         any;
  assignmentFactory:     any;
  activityReportFactory: any;
  
  columns:    any[]   = [{ label: 'activityReport.period', sort: 'start_date' }, { label: 'timeTrackings.operation', sort: 'assignment_title' }]
  column:     string; 
  reverse:    boolean;
  page:       number; 
  totalPages: number; 

  localARs: any;

  readonly COLECTION_NAME: string = 'working_periods';
  constructor($location, $scope, $rootScope, ActivityReportFactory, AppFactory, DatabaseService, ErrFactory, LoaderFactory, AssignmentFactory) {
    Object.defineProperties(this, {
      $location:             { value: $location             },
      $scope:                { value: $scope                },
      $rootScope:            { value: $rootScope            },
      appFactory:            { value: AppFactory            },
      databaseService:       { value: DatabaseService       },
      errFactory:            { value: ErrFactory            },
      loaderFactory:         { value: LoaderFactory         },
      assignmentFactory:     { value: AssignmentFactory     },
      activityReportFactory: { value: ActivityReportFactory },
    });
    this.$rootScope.submitedARs = [];
    this.prepareDefaults();
    this.loadReports();

    $scope.$on('refresh-ar-overview', () => {
      if (this.appFactory.isNetwork()) {
        this.page = 1;
        this.refreshSubmittedReports();
      } else new this.errFactory.NoInternetConnectivity().notify();
    });
  }


  private prepareDefaults(): void {
    if (this.$rootScope.paginationPage) {
      this.page       = this.$rootScope.paginationPage;
      this.column     = this.$rootScope.paginationColumn;
      this.reverse    = this.$rootScope.paginationReverse;
      this.totalPages = this.$rootScope.paginationTotalCount;

      let col = this.columns.find(c => c.sort === this.column);
      if (col) col.reverse = this.reverse;

      this.$rootScope.paginationPage       = null;  
      this.$rootScope.paginationColumn     = null;
      this.$rootScope.paginationReverse    = null;
      this.$rootScope.paginationTotalCount = null;
    } else {
      this.column     = 'start_date';
      this.reverse    = false;
      this.page       = 1;
      this.totalPages = 0;
    }
  }

  private loadReports(): Promise<void> {
    return Promise.resolve()
    .then(() => {
      if (this.appFactory.isNetwork()) this.refreshSubmittedReports();
      else return this.loadLocalReports();
    });
  }

  sortCallback(column: string, reverse: boolean): void {
    this.column  = column;
    this.reverse = reverse;
    this.loadReports();
  }

  paginationCallback(page: number): void {
    this.page = page;
    this.loadReports();
  }

  private refreshSubmittedReports(): Promise<void> {
    this.loaderFactory.show();
    return this.activityReportFactory.SubmittedActivityReport.loadFromServer(this.page, this.column, this.reverse)
    .then(res => {
      this.totalPages = res.meta.total_pages || res.meta.paging.total_pages;
      this.$rootScope.submitedARs = res.working_periods;

      this.$scope.$apply();
      return Promise.all(res.working_periods.map(data => data.save(this.COLECTION_NAME)))
    })
    .catch(err => console.error(err))
		.then(() => this.loaderFactory.hide());
  }

  private loadLocalReports(): void {
    this.loaderFactory.show();
    this.databaseService.getLocalPagination(this.COLECTION_NAME, this.page, this.column, this.reverse)
    .then(res => {
      if (res) {
        this.page       = res.page;
        this.totalPages = res.totalCount;

        return Promise.all([
          Promise.all(res.data.map(item => this.assignmentFactory.getById(item.assignment_id || item.job_id)
            .then(assignment => new this.activityReportFactory.SubmittedActivityReport(Object.assign(item, {assignment})))
          )),
          Promise.all(res.sortedData.map(item => this.assignmentFactory.getById(item.assignment_id || item.job_id)
          .then(assignment => new this.activityReportFactory.SubmittedActivityReport(Object.assign(item, {assignment})))
          ))
        ]);
      }
      this.$location.path('/projects');
      throw new this.errFactory.NoInternetConnectivity().notify();
    })
    .then(res => {
      this.localARs               = res[0];
      this.$rootScope.submitedARs = res[1];

      this.$scope.$apply();
    })
    .catch(err => console.error(err))
    .then(() => this.loaderFactory.hide());
  }

  showSubmited(item): void {
    this.$rootScope.paginationPage       = this.page;
    this.$rootScope.paginationColumn     = this.column;
    this.$rootScope.paginationReverse    = this.reverse;
    this.$rootScope.paginationTotalCount = this.totalPages;

    this.$location.path(`/activity-reports/submited/${item.id}`);
  }

  getCreatorName(ar): string {
    if (ar.createdByTempton) return 'Tempton';
    else return `${ar.created_by.first_name} ${ar.created_by.last_name}`;
  }

  getStartOfWeek(date: Date): Date {
    return Weeks.getStartOfWeek(date);
  }

}

window.app.component('activityReportsSubmited', {
  template: require('scripts/components/activity-reports-overview/activity-reports-submited.html'),
  controller: ['$location', '$scope', '$rootScope', 'ActivityReportFactory', 'AppFactory', 'DatabaseService', 'ErrFactory', 'LoaderFactory', 'AssignmentFactory', ActivityReportsOverview]
});
