class AssetImageComponent {
    static COLLECTION_NAME = 'assets';
    $scope: any;
    dbFactory: any;
    errFactory: any;
    api: any
    /*If identifier is provided, the image will be cached*/
    identifier: string;
    path: string;
    alt: string;
    classList: string;
    asset: any = {};
    loaded: boolean = false;

    constructor($scope: any, DBFactory: any, ErrFactory: any, API: any) {
        Object.defineProperties(this, {
            $scope: {value: $scope},
            dbFactory: {value: DBFactory},
            errFactory: {value: ErrFactory},
            api: {value: API},
        });
    }

    $onInit() {
        this.loadPicture();
    }

    loadPicture() {
        this.getCachedAsset(this.identifier).then((cachedAsset: any) => {
            if (!cachedAsset) {
                return this.api.loadAsset(this.path).then((res: any) => {
                    const base64 = this.arrayBufferToBase64(res.asset)
                    const asset = {
                        id: this.identifier,
                        base64: base64,
                        image: `data:image/jpeg;base64,${base64}`,
                        name: this.alt,
                        saved_at: new Date()
                    };
                    return this.cacheAsset(this.identifier, asset).then(_ => {
                        return asset;
                    });
                })
            } else {
                return cachedAsset;
            }
        }).then((asset: any) => {
            this.asset = asset;
            this.loaded = true;
            this.$scope.$apply();
        }).catch(e => {
            this.loaded = false;
            console.error(e)
        });
    }

    private async getCachedAsset(identifier: string): Promise<any> {
        if (identifier) {
            return this.dbFactory.then((ds: { db: any; }) => ds.db)
                .then((db: any) => {
                    return db.get(AssetImageComponent.COLLECTION_NAME, identifier)
                }).catch((_: any) => {
                    return Promise.resolve(null)
                })
        } else {
            return Promise.resolve(null);
        }
    }

    private async cacheAsset(identifier: string, asset: any): Promise<any> {
        if (identifier) {
            return this.dbFactory.then((ds: { db: any; }) => ds.db)
                .then((db: any) => db.put(AssetImageComponent.COLLECTION_NAME, asset))
                .catch((e: any) => {
                    console.error(e)
                    return Promise.resolve(null)
                });
        }
        return Promise.resolve(null);
    }

    private arrayBufferToBase64(buffer: Iterable<number>) {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
}

window.app.component('assetImage', {
    template: require('scripts/components/asset-image/asset-image.html'),
    controller: ['$scope', 'DBFactory', 'ErrFactory', 'API', AssetImageComponent],
    bindings: {
        identifier: '<',
        path: '<',
        alt: '<',
        classList: '<'
    }
});
